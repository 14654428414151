
.Modal {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 9;
    opacity: 0.0;
    transform: translateX(80px);
    transition: 0.3s opacity, 0.5s transform;
}

.Modal.visible {
    transform: translateX(0px);
    opacity: 1.0;
    pointer-events: auto;
}

.content {
    width: 100%;
    height: 100%;
    padding-top: calc(48px + env(safe-area-inset-top));
    padding-bottom: calc(48px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-y: scroll;
}

