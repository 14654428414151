
.AlertDialog {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.AlertDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    max-width: 380px;
    padding: 24px;
    padding-bottom: 32px;
    border-radius: 8px;
    background-color: var(--appearance-background, transparent);
}

.title {
    font-size: 21px;
    font-weight: 600;
    color: var(--appearance-h1, transparent);
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.description {
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h2, transparent);
    line-height: 24px;
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.boxes{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: transparent;
    color: var(--appearance-h3, transparent);
    box-sizing: border-box;
  }