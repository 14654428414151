
.Item {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;
    box-sizing: border-box;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--appearance-bg-d, transparent);
    cursor: pointer;
}

.stat {
    margin-right: 16px;
    font-size: 20px;
    font-weight: 700;
}

.photo {
    margin-right: 12px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 3px solid transparent;
    box-sizing: border-box;
    background-size: cover;
}

.rank .value {
    margin-top: 2px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
}

.rank .metric {
    margin-top: 8px;
    margin-bottom: 2px;
    font-size: 15px;
    font-weight: 700;
    text-align: right;
}

.info {
    flex-grow: 1;
}

.title {
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 700;
}

.description {
    margin-top: 5px;
    margin-bottom: 2px;
}

/* MARK: Appearance */

.g1 {
    background-color: var(--appearance-bg-a, transparent);
}

.g2 {
    background-color: var(--appearance-bg-b, transparent);
}

.g3 {
    background-color: var(--appearance-bg-c, transparent);
}

.g4 {
    background-color: var(--appearance-bg-d, transparent);
}

.g1 .stat {
    color: var(--appearance-fg-0-a, transparent);
}

.g2 .stat {
    color: var(--appearance-fg-0-b, transparent);
}

.g3 .stat {
    color: var(--appearance-fg-0-c, transparent);
}

.g4 .stat {
    color: var(--appearance-fg-0-d, transparent);
}

.g1 .photo {
    border-color: var(--appearance-fg-0-a, transparent);
}

.g2 .photo {
    border-color: var(--appearance-fg-0-b, transparent);
}

.g3 .photo {
    border-color: var(--appearance-fg-0-c, transparent);
}

.g4 .photo {
    border-color: var(--appearance-fg-0-d, transparent);
}

.g1 .rank {
    color: var(--appearance-fg-0-a, transparent);
}

.g2 .rank {
    color: var(--appearance-fg-0-b, transparent);
}

.g3 .rank {
    color: var(--appearance-fg-0-c, transparent);
}

.g4 .rank {
    color: var(--appearance-fg-0-d, transparent);
}

.g1 .metric {
    color: var(--appearance-fg-2-a, transparent);
}

.g2 .metric {
    color: var(--appearance-fg-2-b, transparent);
}

.g3 .metric {
    color: var(--appearance-fg-2-c, transparent);
}

.g4 .metric {
    color: var(--appearance-fg-2-d, transparent);
}

.g1 .title {
    color: var(--appearance-fg-1-a, transparent);
}

.g2 .title {
    color: var(--appearance-fg-1-b, transparent);
}

.g3 .title {
    color: var(--appearance-fg-1-c, transparent);
}

.g4 .title {
    color: var(--appearance-fg-1-d, transparent);
}

.g1 .description {
    color: var(--appearance-fg-2-a, transparent);
}

.g2 .description {
    color: var(--appearance-fg-2-b, transparent);
}

.g3 .description {
    color: var(--appearance-fg-2-c, transparent);
}

.g4 .description {
    color: var(--appearance-fg-2-d, transparent);
}
