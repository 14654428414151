
.AlertDialog {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.AlertDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    max-width: 380px;
    padding: 24px;
    padding-bottom: 24px;
    border-radius: 8px;
    background-color: var(--appearance-background, transparent);
}

.title {
    font-size: 21px;
    font-weight: 600;
    color: var(--appearance-h1, transparent);
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.description {
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h2, transparent);
    line-height: 24px;
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.photo {
    margin-right: 12px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 3px solid transparent;
    box-sizing: border-box;
    background-size: cover;
    display: block;
    margin-bottom: 15px;;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
}