
.Profile {
    
}

.content {
    padding: 24px;
    box-sizing: border-box;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.photo {
    margin-bottom: 24px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: var(--appearance-b1, transparent);
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    margin-bottom: 12px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: var(--appearance-h1, transparent);
}

.subtitle {
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: var(--appearance-h2, transparent);
}

.settings {

}

.setting {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: var(--appearance-standard-border, transparent);
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
}

.label {
    color: var(--appearance-h2, transparent);
    font-size: 15px;
    font-weight: 600;
}

.value {
    margin-top: 12px;
    color: var(--appearance-h1, transparent);
    font-size: 16px;
    font-weight: 700;
}

.edit {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-image: var(--appearance-edit);
    text-align: center;
    opacity: 0.5;
}
