
.ShareDialog {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.ShareDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px;
    padding-bottom: calc(48px + env(safe-area-inset-bottom) + 24px);
    border-radius: 16px;
    overflow: hidden;
}

.social {
    width: 120px;
    height: 120px;
    opacity: 0.7;
}

.instagram {
    background-image: url('../../../public/share_instagram.svg');
}

.facebook {
    background-image: url('../../../public/share_fb.svg');
}

.message {
    background-image: url('../../../public/share_sms.svg');
}

.twitter {
    background-image: url('../../../public/share_twitter.svg');
}
