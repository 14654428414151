
.BadgeItem {
    width: 100%;
    padding-top: 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.content {
    display: flex;
    align-items: center;
}

.photo {
    margin-right: 24px;
    width: 84px;
    height: 84px;
    background-size: 84px 84px;
}

.info {
    display: flex;
    flex-direction: column;
}

.title {
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 700;
    color: var(--appearance-h1, transparent);
}

.description {
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 15px;
    font-weight: 700;
    color: var(--appearance-h3, transparent);
}
