
.RewardsBanner {
    display: flex;
    /* position: fixed;
    top: calc(env(safe-area-inset-top) + 64px);
    left: 20px;
    width: calc(100% - 40px); */
    width: 100%;
    padding: 28px 20px;
    box-sizing: border-box;
    background-color: var(--appearance-stat-background-b);
    pointer-events: none;
    z-index: 10;
    border-radius: 16px;
    backdrop-filter: blur(24px);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: translateY(-20px);
    opacity: 0.0;
    height: 0px;
    /* transition: opacity 1.2s 3.8s; */
    transition: opacity 1.2s 2.0s;
    /* tr
    transition: 0.3s opacity ease, 0.4s transform ease;
    transition-delay: 2.4s; */
}

.RewardsBanner.visible {
    opacity: 1.0;
    transform: translateY(0px);
    pointer-events: auto;
    height: auto;
}

.info {
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    color: var(--appearance-stat-text-b);
}

.icon {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%23EAAB78'%3E%3Crect fill='none' height='24' width='24'/%3E%3Cpath d='M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M12,14 c-1.65,0-3-1.35-3-3V5h6v6C15,12.65,13.65,14,12,14z M19,8c0,1.3-0.84,2.4-2,2.82V7h2V8z'/%3E%3C/svg%3E");
}

.info .title {
    font-size: 17px;
    margin-bottom: 2px;
    line-height: 32px   ;
}