
.Rewards {
    /* display: flex; */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* padding: 24px; */
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(51, 51, 51, 0.7);
    z-index: 10;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity;
}

.Rewards.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.container {
    /* display: flex;
    justify-content: center; */
    position: absolute;
    /* top: 0px; */
    top: calc(env(safe-area-inset-top) + 50px);
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    /* padding: 20px; */
    box-sizing: border-box;
    /* padding-top: calc(env(safe-area-inset-top) + 50px); */
    padding-bottom: calc(48px + env(safe-area-inset-bottom) + 24px);
    /* border-radius: 20px; */
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
    /* overflow: hidden;     */
    background-color: var(--appearance-background-r);
    /* background-color: darkgreen; */
    transition: 0.3s opacity ease;
    opacity: 0.0;
}

.Rewards.visible .container {
    opacity: 1.0;
}

.header {
    margin-top: 20px;
    margin-bottom: 24px;
    background-color: var(--appearance-background-r);
    padding: 0px 24px;
    box-sizing: border-box;
}

.nav {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(env(safe-area-inset-top) + 50px);
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
    background-color: var(--appearance-background-r);
    opacity: 1.0;
}

.navTitle {
    font-size: 17px;
    font-weight: 600;
    line-height: 50px;
}

.navIcon {
    margin-right: 16px;
    width: 50px;
    height: 50px;

}

.navClose {
    position: absolute;
    top: calc(env(safe-area-inset-top) + 8px);
    right: 24px;
    width: 36px;
    height: 36px;
    background-image: var(--appearance-nav-close);
    /* background-color: turquoise; */
    background-position: center right;
    background-repeat: no-repeat;
}

.sectionTitle {
    font-size: 21px;
    font-weight: 700;
}

.sectionSubtitle {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.availableCard {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    /* min-height: 200px; */
    width: 160px;
    padding: 20px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 16px;
    /* margin-right: 20px; */
    background-color: var(--appearance-card-r);
    cursor: pointer;
}

.cardSpacer {
    flex-shrink: 0;
    width: 4px;
}

.availableCard .top {
    display: flex;
    margin-bottom: 16px;
    /* justify-content: space-between; */
}

.availableCard .icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    /* border-radius: 18px; */
    /* background-image: url('https://geekyinteractive.com/assets/icon.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgb(51, 51, 51); */
}

.availableCard .lock {
    margin-top: 2px;
    /* width: 24px;
    height: 24px; */
    /* background-image: var(--appearance-lock); */
    width: 8px;
    height: 8px;
    border-radius: 4px;
    /* background-color:  */
}

.availableCard .text {
    /* margin-top: 1px; */
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
}

.availableCard .company {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.availableCard .title {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
}

.availableCard .spacer {
    flex-grow: 1;
}


.confirmDialog {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* padding: 24px; */
    justify-content: center;
    align-items: center;
    background-color: env(--appearance-background-blur-b);
    backdrop-filter: blur(24px);
    /* transition: 0.3s background-color, 0.3s backdrop-filter; */
    z-index: 11;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity;
}

.confirmDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.confirmContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.confirmIcon {
    margin-bottom: 20px;
    width: 72px;
    height: 72px;
    background-size: cover;
    border-radius: 16px;
    background-color: rgba(51, 51, 51, 0.3);
}

.confirmMessage {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    padding: 0px 24px;
    box-sizing: border-box;
}

.confirmPreview {
    display: flex;
    flex-grow: 0;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 4px;
    overflow-x: scroll;
    padding: 0px 24px;
    box-sizing: border-box;
}

.confirmActions {
    margin-top: 24px;
    display: flex;
    width: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
}

.confirmActions.disabled {
    opacity: 0.5;
    pointer-events: none;
}







.redeemDialog {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* padding: 24px; */
    justify-content: center;
    align-items: center;
    background-color: env(--appearance-background-blur-b);
    backdrop-filter: blur(24px);
    /* transition: 0.3s background-color, 0.3s backdrop-filter; */
    z-index: 11;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity;
}

.redeemDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.redeemContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    box-sizing: border-box;
}

.redeemContainer.visible {
    display: flex;
}

.redeemingContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    box-sizing: border-box;
}

.redeemingContainer.visible {
    display: flex;
}

.redeemIcon {
    margin-bottom: 20px;
    width: 72px;
    height: 72px;
    background-size: cover;
    border-radius: 16px;
    background-color: rgba(51, 51, 51, 0.3);
}

.redeemMessage {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.redeemMessage.alert {
    color: rgba(229, 71, 82, 1.0);
}

.redeemActions {
    margin-top: 24px;
    display: flex;
    width: 100%;
}

.redeemActions.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.redeemCode {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 8px;
}

.redeemCode.animating {
    animation: scaling 3s infinite ease-in-out;
}

.redeemCounter {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

/* .redeemCounter  */

.progressCard {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    /* min-height: 200px; */
    width: 160px;
    padding: 20px 20px;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 16px;
    /* margin-right: 20px; */
    /* background-color: teal; */
    background-color: var(--appearance-button-bg);
    cursor: pointer;
}

.progressCard .top {
    display: flex;
    margin-bottom: 16px;
    /* justify-content: space-between; */
}

.progressCard .icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    /* border-radius: 18px; */
    /* background-image: url('https://geekyinteractive.com/assets/icon.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: rgb(51, 51, 51); */
}

.progressCard .lock {
    margin-top: 2px;
    /* width: 24px;
    height: 24px; */
    /* background-image: var(--appearance-lock); */
    width: 8px;
    height: 8px;
    border-radius: 4px;
    /* background-color:  */
}

.progressCard .text {
    /* margin-top: 1px; */
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
}

.progressCard .company {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
}

.progressCard .title {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
}

.progressCard .spacer {
    flex-grow: 1;
}




.social {
    width: 120px;
    height: 120px;
    opacity: 0.7;
}

.instagram {
    background-image: url('../../../public/share_instagram.svg');
}

.facebook {
    background-image: url('../../../public/share_fb.svg');
}

.message {
    background-image: url('../../../public/share_sms.svg');
}

.twitter {
    background-image: url('../../../public/share_twitter.svg');
}

/* @keyframes  */

@keyframes scaling {
	0% {
		transform: scale(0.8);
	}

	50% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.8);
	}
}