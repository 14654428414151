
.TitleBar {
    position: fixed;
    top: 0px;
    width: 100%;
    height: calc(48px + env(safe-area-inset-top));
    z-index: 9;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    padding-top: env(safe-area-inset-top);
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.item {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.title {
    flex-grow: 1;
    font-size: 18px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
}

.back {
    width: 36px;
    height: 36px;
    background-image: var(--appearance-back);
    /* background-image: url('/close.svg'); */
    background-size: 36px 36px;
    background-position: center;
    background-repeat: no-repeat;
}
