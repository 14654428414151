
.ProgressCircle {
    transform: scale(0.5) rotate(-90deg);
    opacity: 0.0;
    transition: 0.3s opacity, 0.7s transform;
    /* transition: 0.7s opacity, 1.2s transform; */
}

.ProgressCircle.visible {
    transform: scale(1.0) rotate(0deg);
    opacity: 1.0;
}

.container {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
    shape-rendering: geometricPrecision;
}

.circleOutline {
    fill: none;
    stroke-width: 2;
}

.circleProgress {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 0px 100px;
    transition: stroke-dasharray 1.2s ease-in-out;
}
