
.GoalStepper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.number {
    text-align: center;
    line-height: 40px;
    font-size: 28px;
    font-weight: 500;
    color: var(--appearance-h1, transparent);
    opacity: 0.8;
}

.arrows {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
}

.arrow {
    width: 44px;
    height: 32px;
    cursor: pointer;
    opacity: 0.8;
}

.arrow.up {
    background-image: var(--appearance-step-up);
}

.arrow.down {
    background-image: var(--appearance-step-down);
}

.spacer {
    width: 44px;
    height: 32px;
}
