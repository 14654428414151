
.AlertDialog {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.AlertDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    max-width: 380px;
    min-width: 280px;
    padding: 24px;
    padding-bottom: 24px;
    border-radius: 8px;
    background-color: var(--appearance-background, transparent);
}

.title {
    font-size: 21px;
    font-weight: 600;
    color: var(--appearance-h1, transparent);
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.description {
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h2, transparent);
    line-height: 24px;
    text-align: center;
}
.descriptionError {
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h2, transparent);
    line-height: 24px;
    text-align: center;
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.action {
  margin-top: 12px;
  /* flex-shrink: 0; */
  width: 100%;
  height: 50px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 50px;
  color: var(--appearance-h1, transparent);
  background-color: var(--appearance-b1, transparent);
  text-align: center;
  cursor: pointer;
}
.boxes{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: transparent;
    box-sizing: border-box;
  }
  .textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: transparent;
    box-sizing: border-box;
}
