
.SecondaryInfoModal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(51, 51, 51, 0.0);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.SecondaryInfoModal.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-bottom));
    padding-bottom: 24px;
    box-sizing: border-box;
    background-color: var(--appearance-background, transparent);
    overflow: hidden;
}

.cards {
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
    transition: 0.5s transform;
    transform: translate3d(0, 0, 0);
}

.card {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* padding-left: 24px;
    padding-right: 24px; */
}

.photo {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: var(--appearance-b1, transparent);
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 700;
    color: var(--appearance-h1, transparent);
    line-height: 26px;
    text-align: center;
}

.description {
    margin-bottom: 32px;
    font-size: 15px;
    font-weight: 700;
    color: var(--appearance-h3, transparent);
    line-height: 26px;
    text-align: center;
}

.list {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    overflow: scroll;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: var(--appearance-standard-border, transparent);
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
}

.itemTitle {
    font-size: 16px;
    font-weight: 700;
    color: var(--appearance-h1, transparent);
}

.itemSubtitle {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 21px;
}

.check {
    flex-basis: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 12px;
    border-radius: 6px;
    background-color: royalblue;
    opacity: 0.0;
}

.check.visible {
    opacity: 1.0;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: var(--appearance-background, transparent);
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
    box-sizing: border-box;
    z-index: 1;
}

.done {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: var(--appearance-h1, transparent);
    background-color: var(--appearance-b1, transparent);
    text-align: center;
    cursor: pointer;
}

.next {
    margin-top: 12px;
    width: 100%;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--appearance-h3, transparent);
    text-align: center;
    cursor: pointer;
}

.textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 12px;
    background-color: transparent;
    box-sizing: border-box;
}

.photoUpload {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: rgba(51, 51, 51, 1.0);
    background-size: cover;
}

.photoUpload input {
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: rebeccapurple;
    opacity: 0.0;
}

.photoUpload .add {
    position: absolute;
    width: 120px;
    height: 120px;
    font-size: 36px;
    font-weight: 400;
    line-height: 116px;
    text-align: center;
    color: white;
    opacity: 0.7;
}

.photoUpload .loading {
    color: white;
    font-size: 15px;
    font-weight: 700;
    line-height: 116px;
    text-align: center;
    opacity: 0.7;
}

.close {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 36px;
    height: 36px;
    background-image: var(--appearance-close);
    background-size: 36px 36px;
    background-position: center;
    background-repeat: no-repeat;
}