
.LinkPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgb(26, 25, 25);
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 420px;
    padding: 24px;
    box-sizing: border-box;
}

.title {
    margin-bottom: 12px;
    font-size: 21px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.description {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    line-height: 24px;
    text-align: center;
}

.photo {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: rgb(46, 43, 42);
    background-size: cover;
    background-repeat: no-repeat;
}

.done {
    margin-top: 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: rgb(255, 255, 255);
    background-color: rgb(46, 43, 42);
    text-align: center;
    cursor: pointer;
}

.watermark {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 60px;
    height: 60px;
    background-image: url('../../../public/watermark.svg');
}
