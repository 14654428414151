
.NotificationModal {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 0;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(50px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.NotificationModal.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    width: 100%;
    padding: 24px;
    padding-bottom: calc(48px + env(safe-area-inset-bottom) + 24px);
    border-radius: 16px;
    background-color: var(--appearance-background, transparent);
    overflow: hidden;
}

.cards {
    display: flex;
    height: 360px;
    transition: 0.7s transform;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
}

.photo {
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: var(--appearance-b1, transparent);
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    margin-bottom: 12px;
    font-size: 21px;
    font-weight: 600;
    color: var(--appearance-h1, transparent);
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.description {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
}

.share {
    display: inline-block;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: var(--appearance-h1, transparent);
    background-color: var(--appearance-b1, transparent);
    text-align: center;
    cursor: pointer;
}

.next {
    margin-top: 12px;
    width: 100%;
    height: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--appearance-h3, transparent);
    text-align: center;
    cursor: pointer;
}
