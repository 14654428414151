
.TabBar {
    position: fixed;
    /* position: -webkit-sticky; */
    bottom: 0px;
    width: 100%;
    height: calc(48px + env(safe-area-inset-bottom));
    z-index: 10;
}

.tabs {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 48px;
}

.tab {
    flex-grow: 1;
    text-align: center;
    line-height: 64px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s opacity, 0.7s color;
}

.tab.selected {
    opacity: 1.0;
}
