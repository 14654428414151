
.SignIn {
    
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px; /* ;) */
    padding-top: 64px;
    padding-left: 20px;
    padding-right: 20px;
}

.container.pending {
    opacity: 0.5;
    pointer-events: none;
}

.container.finished {
    opacity: 0.0;
    transform: scale(1.2);
}

.title {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 700;
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3em;
}

.description a {
    opacity: 0.9;
    text-decoration: underline;
}

.textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: transparent;
    color: var(--appearance-h1, transparent);
    box-sizing: border-box;
}


.submit {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    /* pointer-events: none; */
}

/* .submit.enabled {
    pointer-events: auto;
} */
