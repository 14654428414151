
.GroupSelector {
    width: 100%;
    height: 40px;
}

.categories {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 40px;
}

.category {
    flex-grow: 1;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s opacity;
}

.category.selected {
    opacity: 1.0;
}
