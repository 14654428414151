
.BadgeShow {
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 420px;
    box-sizing: border-box;
}

.photo {
    margin-top: 36px;
    width: 160px;
    height: 160px;
    background-size: 160px 160px;
}

.title {
    margin-top: 24px;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    color: var(--appearance-h1, transparent);
    text-align: center;
}

.label {
    margin-top: 36px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 700;
    color: var(--appearance-h2, transparent);
    text-align: center;
}

.description {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 700;
    color: var(--appearance-h3, transparent);
    text-align: center;
    line-height: 24px;
}