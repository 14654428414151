
.content {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px 24px;
    padding-left: 16px;
    padding-right: 16px;
}

.share {
    display: inline-block;
    height: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.hidden{
	width: 100%;
	height: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.label{
    cursor: pointer;
}
