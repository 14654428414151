
.Item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 28px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: var(--appearance-bg-b, transparent);
    cursor: pointer;
}

.title {
    font-size: 17px;
    font-weight: 700;
    color: var(--appearance-fg-1-a, transparent);
    text-align: center;
}
